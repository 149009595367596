<template>
  <b-overlay :show="formShow" rounded="sm" no-fade>
    <b-card>
      <validation-observer ref="accountRules">
        <div>
          <b-row>
            <table class="table table-sm">
              <thead>
                <tr>
                  <th scope="col">Add New Account</th>
                </tr>
              </thead>
            </table>
          </b-row>

          <b-row>
            <b-col cols="12" md="6" class="mt-2">
              <b-form-group>
                <label for="vehicle-type" class="border-bottom">Account Type* </label>
                <validation-provider #default="{ errors }" name="Vehicle Title Type" rules="required">
                  <b-form-radio-group class="border-bottom pb-1">
                    <b-form-radio v-model="accountData.accountType" name="vehicle-type" value="Asset">
                      Asset
                    </b-form-radio>
                    <b-form-radio v-model="accountData.accountType" name="vehicle-type" value="Liability">
                      Liability
                    </b-form-radio>
                    <b-form-radio v-model="accountData.accountType" name="vehicle-type" value="Equity">
                      Equity
                    </b-form-radio>
                    <b-form-radio v-model="accountData.accountType" name="vehicle-type" value="Income">
                      Income
                    </b-form-radio>
                    <b-form-radio v-model="accountData.accountType" name="vehicle-type" value="Expenses">
                      Expenses
                    </b-form-radio>
                  </b-form-radio-group>

                  <small class="text-danger" v-if="accountData.accountType == null">Account Type is required </small>
                  <small class="text-danger" v-else>{{ errors[0] }} </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="6">
              <b-form-group>
                <label for="accountTitle">Account Name*</label>
                <validation-provider #default="{ errors }" name="Appraised Vehicle Price" rules="required">
                  <b-form-input id="accountTitle" v-model="accountData.accountTitle" type="text" />
                  <small class="text-danger" v-if="accountData.accountTitle == null">Account Name is required </small>
                  <small class="text-danger" v-else>{{ errors[0] }} </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Account Number" label-for="Account Number">
                <b-form-input id="accountNumber" v-model="accountData.accountNumber" class="form-control" type="number" />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Subclass" label-for="Category">
                <v-select v-model="accountData.accountCategory" :disabled="accountData.accountType == null || accountData.accountType == 'Equity'" :options="dropCategories" input-id="category-data"> </v-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-show="accountData.accountType != null">
            <b-col cols="12" md="6">
              <b-form-group label="Opening Balance" label-for="Opening Balance">
                <cleave id="openingBalance" v-model="accountData.openingBalance" class="form-control" :raw="true" :options="options.number" />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="accountData.accountType == 'Equity'">
            <b-col cols="12" md="6">
              <b-form-group label="Cash Flow Classification" label-for="Category">
                <v-select :options="cashFlowCategories" input-id="category-data"> </v-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-card-body class="invoice-padding pt-0 mt-1">
              <b-col cols="12">
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" @click="saveAccount" variant="primary" class="mr-1 float-right">
                  Save
                </b-button>
              </b-col>
            </b-card-body>
          </b-row>
        </div>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormRadio, BInputGroup, BFormInput, BFormRadioGroup, BFormTextarea } from 'bootstrap-vue';
import store from '@/store';
import VueNumericInput from 'vue-numeric-input';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';
import flatPickr from 'vue-flatpickr-component';
import router from '@/router';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import accountingStoreModule from '@/views/accounting/accountingStoreModule.js';
import { onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import { required, email } from '@validations';
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BInputGroup,
    BFormInput,
    BFormRadioGroup,
    BFormTextarea,
    vSelect,
    //validation
    ValidationProvider,
    ValidationObserver,

    VueNumericInput,
    ToastificationContent,
    Cleave,
    flatPickr,
  },

  data() {
    // { key: 'quoteDate', show: true, label: 'Quote Date', class: 'text-center', sortable: false, class: 'text-center', thStyle: 'width: 10%' },

    return {
      dropCustomers: [],
      cashFlowCategories: [],
      dropCategories: [],

      accountData: {
        customerId: null,
        id: null,
        date: null,

        openingBalance: null,
        accountTitle: null,
        accountNumber: null,
        accountType: null,
        accountCategory: null,
      },

      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      colWidthFactor: null,
      totalRows: 0,
      baseURL: store.state.app.baseURL,
      formShow: false,

      statusType: null,
      status: null,

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralPositiveOnly: true,
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },
      required,
      email,
    };
  },

  directives: {
    Ripple,
  },

  computed: {},

  watch: {
    'accountData.accountType': {
      handler: function(val, before) {
        if (val != null || val != 'None') {
          switch (val) {
            case 'Asset':
              this.dropCategories = ['Bank Account', 'Other Asset'];
              break;
            case 'Liability':
              this.dropCategories = ['Credit Card', 'Loan Account', 'Other Liability'];
              break;
            case 'Equity':
              this.cashFlowCategories = ['Operating', 'Investing', 'Financing'];
              this.accountData.accountCategory = null;

              break;
            case 'Income':
              this.dropCategories = ['Sales', 'Non-Operating Income'];

              break;
            case 'Expenses':
              this.dropCategories = ['Cost of Sales', 'Operating Expense', 'Non-Operating Expense'];

              break;
            default:
              break;
          }
        }
      },
    },
  },

  methods: {
    categorySelect(val) {},

    getCustomerDrops() {
      store
        .dispatch('accounting/getCustomerDropDowns')
        .then((response) => {
          this.dropCustomers = response.data;
          this.formShow = false;
        })
        .catch((error) => {});
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },

    saveAccount() {
      this.formShow = true;

      this.$refs.accountRules.validate().then((success) => {
        if (success) {
          if (router.currentRoute.params.id) {
            store
              .dispatch('accounting/updateAccount', this.accountData)
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ Entry has been saved successfully',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });

                router.push({ name: 'account-list' });
              })
              .catch((e) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Something went wrong',
                    text: 'Please try again or report an issue to support',
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                });
              });
          } else {
            store
              .dispatch('accounting/saveAccount', this.accountData)
              .then((response) => {
                if (response.status == 201) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Successful',
                      text: '✔️ Entry has been saved successfully',
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  });

                  this.formShow = false;

                  router.push({ name: 'account-list' });
                }
              })
              .catch((e) => {
                this.formShow = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Something went wrong',
                    text: 'Please try again or report an issue to support',
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                });
              });
          }
        } else {
          this.formShow = false;
        }
      });
    },

    getByIdExpense() {
      if (router.currentRoute.params.id) {
        store
          .dispatch('accounting/fetchAccountById', { id: router.currentRoute.params.id })
          .then((response) => {
            this.accountData = response.data;

            this.formShow = false;
          })
          .catch((error) => {
            console.log(error);
            this.formShow = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                text: 'Please try again or report an issue to support',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
            router.push({ name: 'account-list' });
          });
      } else {
        this.formShow = false;
      }
    },
  },
  setup() {
    const ACCOUNTING_APP_STORE_MODULE_NAME = 'accounting';

    // Register module
    if (!store.hasModule(ACCOUNTING_APP_STORE_MODULE_NAME)) store.registerModule(ACCOUNTING_APP_STORE_MODULE_NAME, accountingStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACCOUNTING_APP_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNTING_APP_STORE_MODULE_NAME);
    });
  },
  created() {
    this.formShow = true;
    this.getCustomerDrops();
    this.getByIdExpense();
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/vue-select.scss';

.form-item-section {
  background-color: #fff;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
